$(function(){
  $( '#ajax-login-form' ).on( 'submit', function(e){
    e.preventDefault();

    var form = $( this );

    var loginData = {
      username: $( 'input[name=email]', $( this ) ).val(),
      password: $( 'input[name=password]', $( this ) ).val(),
      _remember_me: $( 'input[name=_remember_me]', $( this ) ).prop( 'checked' ),
    };

    var loginErrorMessage = $( this ).attr( 'data-login-error-message' );
    var loginErrorContainer = $( '.ajax-login-error', $( this ) );

    loginData = JSON.stringify( loginData );

    loginErrorContainer.addClass( 'alert alert-danger d-none' ).html( '' );

    $.ajax({
      type: 'POST',
      url: '/login-ajax',
      dataType: 'json',
      data: loginData,
      contentType: 'application/json;charset=UTF-8',
      timeout: 3000, // 3 seconds
      success: function( jsonData ) {
        var skip_trigger = false;

        // set logged in html
        if ( jsonData.user ) {
            $( 'html' ).attr( 'logged', jsonData.user );
        }

        if ( form.attr( 'action' ) != '' ) {
          document.location = form.attr( 'action' );
        }
      },
      error: function( jqXHR, textStatus, errorThrown ) {
        loginErrorContainer.removeClass( 'd-none' ).html( loginErrorMessage );
      }
    });
  });
});
