
let counter = 0;

const cartCounterLink = $(".shop-link");
const cartCounter = ".shop-counter";

const spinnerLoading = `<div class="sk-chase">
<div class="sk-chase-dot"></div>
<div class="sk-chase-dot"></div>
<div class="sk-chase-dot"></div>
<div class="sk-chase-dot"></div>
<div class="sk-chase-dot"></div>
<div class="sk-chase-dot"></div>
</div>`;

function req( trigger, action, url ) {
    const card = $(trigger).closest(".card");

    $.ajax({
        url: url,
        beforeSend: function() {
            $(trigger).html( spinnerLoading )
            $(trigger).prop("disabled", true);
        },
        success: function( data ) {
            counter = data.count > 9 ? '9+' : data.count;
            const msg = data.Success;

            if( action === "add" ) {
                $(trigger).text( $(trigger).attr("data-label-remove") );
                $(trigger).removeClass( "add" );
                $(trigger).addClass("remove");
            }
            if( action === "remove" ) {
                $(trigger).text( $(trigger).attr("data-label-add") );
                $(trigger).removeClass( "remove" );
                $(trigger).addClass("add");

                const cartItem = $(trigger).closest(".cart-item");
                if( cartItem.length ) {
                    $(cartItem).fadeOut(2000).remove();

                    let prices = [];
                    $(".cart-item[data-price]").each( function() {
                        const value = $(this).attr("data-price");
                        const valueParsed = Number(value);
                        prices.push( valueParsed );
                    });

                    if( !prices.length ) {
                        $(".next-step").parent().addClass("no-prev");
                        $(".next-step").fadeOut(2000).remove();
                        $(".steps-indicator .not-cart-empty").addClass("d-none");
                        $(".steps-indicator .is-cart-empty").removeClass("d-none");
                        $(".form-container").fadeOut(2000).remove();
                    }else {
                        const cartTotal = cart.priceCal(prices);

                        $(".cart-total").text(cartTotal + ".-");
                    }

                }
            }

            if( card.length ) {
                cart.renderAlert(card, msg);
            }
            $(trigger).prop("disabled", false);
            cart.updateCounter();
        },
        error: function(res) {
            const msg = res.responseJSON.Error;
            if( card.length ) {
                cart.renderAlert(card, msg, 'danger', true, 5000);
            }
            $(trigger).text( $(trigger).attr("data-label-add") );
            $(trigger).prop("disabled", false);
        }

    });
}

const cart = {
    addItem: function( trigger, item ) {

        if( item ) {
            req( trigger, "add", item )
        }
    },
    removeItem: function( trigger, item ) {
        if( item ) {
            req( trigger, "remove", item )
        }
    },

    priceCal(prices) {
        console.log(prices);
        if( prices.length) {
            return prices.reduce( (a, b) => a + b );
        }
    },

    updateCounter: function() {

        if( !$( cartCounter ).length ) {
            $( cartCounterLink ).prepend( $(`<span class="shop-counter">${counter}</span>`));
        }else {
            $(".shop-counter").text( counter );
        }

        if(!counter) {
            $('.shop-counter').remove()
            $('#go-to-cart-action-container').addClass('d-none')
        }else {
            $('#go-to-cart-action-container').removeClass('d-none')
        }
    },
    /**
     *
     * @param {*} parent Node | element class | element id...
     * @param {*} msg string
     * @param {*} autoClose Boolean default true
     * @param {*} delay in ms, default 3000 ( autoClose => true is needed )
     */
    renderAlert(parent, msg, type = 'success', autoClose = true, delay =  3000) {
        const closetrigger = !autoClose ? `<button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>` : '';

        const template = `<div class="alert fade show alert-${type}" role="alert">
            ${msg}
            ${closetrigger}
        </div>`;

        $(parent).prepend( $( template ) );

        if( autoClose ) {
            setTimeout( function() {
                $(parent).children('.alert').alert('close');
            }, delay);
        }
    }
}
// Card promo
$( ".card-price-selector").on( "change", function(e) {
    const hasValue = ( this.value && this.value !== '' );
    const dataset = this.selectedOptions[ 0 ].dataset;
    const cta = this.closest( ".card-promo" ).querySelector( ".cart-action" );

    cta.setAttribute( "data-cart-action-add", dataset.cartActionAdd );
    cta.setAttribute( "data-cart-action-remove", dataset.cartActionRemove );
    cta.classList.toggle( "disabled", !hasValue );
})

$(".cart-action").on("click", function (e) {
    e.preventDefault();
    let priceSelector = null;
    if ( this.closest( ".card-promo" ) && this.closest( ".card-promo" ).querySelector( ".card-price-selector" ) ) {
        priceSelector = this.closest( ".card-promo" ).querySelector( ".card-price-selector" );

    }

    if( $(this).hasClass("add") ) {
        cart.addItem( this, $(this).attr('data-cart-action-add') );
        if ( priceSelector ) {
            priceSelector.classList.add("invisible");
        }
    }
    if( $(this).hasClass("remove") ) {
        cart.removeItem( this, $(this).attr('data-cart-action-remove') );
        if ( priceSelector ) {
            priceSelector.classList.remove("invisible");
        }
    }
});
