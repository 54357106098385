import {
  responsive
} from "../helpers/helpers";

// Carousel de la homepage
$('#homepage-carousel').on('slid.bs.carousel', function (e) {
  const indicator = $(e.target).find(".carousel-indicators li.active");
  const caption = $(".carousel-caption").find("p[data-caption-slide]");

  $(caption).fadeOut(300, "swing", function () {
    $(caption).text($(indicator).attr("data-caption-text")).fadeIn(1000);
  });

});



// convert block to carousel in mobile and tablet
// Au chargement de la page
$(function () {

  let slickConfig = {
    dots: $('.homepage').length > 0 ? true : false,
    arrows: $('.homepage').length > 0 ? false : true,
    infinite: true,
    speed: 500,
    fade: $('.homepage').length > 0 ? false : true,
    cssEase: 'linear',
    adaptiveHeight: true,
    slidesToShow: $('.homepage').length > 0 ? 2 : 1,
    slidesToScroll: $('.homepage').length > 0 ? 2 : 1,

  }

  if (responsive(window, $('.processes').length > 0 ? 736 : 812, 'max-width')) {
    if ($('.homepage').length > 0) {
      slickConfig.dots = window.innerWidth >= 768 ? true : false;
      slickConfig.arrows = window.innerWidth >= 768 ? false : true,
        slickConfig.fade = window.innerWidth >= 768 ? false : true,
        slickConfig.slidesToShow = window.innerWidth >= 768 ? 2 : 1,
        slickConfig.slidesToScroll = 1
    }
    $('.mutate-carousel').slick(slickConfig);

  } else {
    $('.mutate-carousel.slick-slider').length > 0 ? $('.mutate-carousel').slick('destroy') : null;
  }


  // convert block to carousel in mobile and tablet
  // Au resize de la fenêtre
  $(window).on("resize", function () {
    if ($('.homepage').length > 0) {
      slickConfig.dots = window.innerWidth >= 768 ? true : false;
      slickConfig.arrows = window.innerWidth >= 768 ? false : true,
        slickConfig.fade = window.innerWidth >= 768 ? false : true,
        slickConfig.slidesToShow = window.innerWidth >= 768 ? 2 : 1,
        slickConfig.slidesToScroll = 1
    }
    if (responsive(window, $('.processes').length > 0 ? 736 : 812, 'max-width')) {
      $('.mutate-carousel').slick(slickConfig);

    } else {
      $('.mutate-carousel.slick-slider').length > 0 ? $('.mutate-carousel').slick('destroy') : null;
    }
  });


  // Au changement de l'orientation (portrait / landscape)
  // Find matches
  const mql = window.matchMedia("(orientation: portrait)");


  try {
    // Chrome & Firefox
    mql.addEventListener('change', function (e) {
      if (window.innerWidth <= $('.processes').length > 0 ? 736 : 812) {
        if ($('.homepage').length > 0) {
          slickConfig.dots = window.innerWidth >= 768 ? true : false;
          slickConfig.arrows = window.innerWidth >= 768 ? false : true,
            slickConfig.fade = window.innerWidth >= 768 ? false : true,
            slickConfig.slidesToShow = window.innerWidth >= 768 ? 2 : 1,
            slickConfig.slidesToScroll = 1
        }

        if (('.mutate-carousel.slick-slider').length === 0) {
          $('.mutate-carousel').slick(slickConfig);
        } else {
          $('.mutate-carousel').slick('unslick').slick('reinit');
        }
      } else {
        $('.mutate-carousel.slick-slider').length > 0 ? $('.mutate-carousel').slick('destroy') : null;
      }
    });

  } catch (e1) {
    try {
      // Safari
      mql.addListener(function (e) {

        if (window.innerWidth <= $('.processes').length > 0 ? 736 : 812) {
          if ($('.homepage').length > 0) {
            slickConfig.dots = window.innerWidth >= 768 ? true : false;
            slickConfig.arrows = window.innerWidth >= 768 ? false : true,
              slickConfig.fade = window.innerWidth >= 768 ? false : true,
              slickConfig.slidesToShow = window.innerWidth >= 768 ? 2 : 1,
              slickConfig.slidesToScroll = 1
          }
          if (('.mutate-carousel.slick-slider').length === 0) {

            $('.mutate-carousel').slick(slickConfig);
          } else {
            $('.mutate-carousel').slick('unslick').slick('reinit');
          }
        } else {
          $('.mutate-carousel.slick-slider').length > 0 ? $('.mutate-carousel').slick('destroy') : null;
        }
      });
    } catch (e2) {
      console.error(e2);
    }
  }
});



// Carousel technologies (page technologie)
$('#technologies-carousel').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  fade: true,
  adaptiveHeight: true,
  asNavFor: '#technologies-carousel-nav',
  appendArrows: '.technologies-navigation',

  responsive: [{
    breakpoint: 1023.98,
    settings: {
      arrows: false
    }
  }, ]
});

$('#technologies-carousel-nav').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  asNavFor: '#technologies-carousel',
  dots: false,
  arrows: false,
  centerMode: true,
  focusOnSelect: true
});


// Carousel technologies (page technologie)
$('#carousel-promos').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  adaptiveHeight: true,
  arrows: true,

  responsive: [
    {
      breakpoint: 536,
      settings: {
        slidesToShow: 1,
      }
    }, 
    {
      breakpoint: 1023.98,
      settings: {
        slidesToShow: 2,
        dots: false,
      }
    }, 
  ]
});
