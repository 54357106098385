$(function(){
  $( '.form-animated .form-control' ).on( 'focusin', function(){
    $(this).closest( '.form-group' ).addClass( 'form-animated-focus' );
  });

  $( '.form-animated .form-control' ).on( 'focusout', function(){
    if ( $(this).val() == '' ) {
      $(this).closest( '.form-group' ).removeClass( 'form-animated-focus' );
    }
  });

  updateFormAnimatedFocus();

  $( '.link-question' ).on( 'click', function(e){
    var question = $(this).attr( 'data-question' );

    if ( !confirm( question ) ) {
      e.preventDefault();
    }
  });
});

function updateFormAnimatedFocus() {
  $( '.form-animated .form-control' ).each(function(){
    if ( $(this).val() != '' ) {
      $(this).closest( '.form-group' ).addClass( 'form-animated-focus' );
    } else {
      $(this).closest( '.form-group' ).removeClass( 'form-animated-focus' );
    }
  });
}
