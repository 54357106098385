/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

 // Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
 // import $ from 'jquery';
  import './bootstrap';
 //console.log('Hello Webpack Encore! Edit me in assets/js/app.js');

 const $ = require( "jquery" );
 global.$ = global.jQuery = $;

 require( "bootstrap" );

 import slick from "slick-carousel";
 import 'slick-carousel/slick/slick.scss';
 import 'slick-carousel/slick/slick-theme.scss';
 // any CSS you import will output into a single css file (app.css in this case)
import '../css/app.scss';


require("./helpers/helpers");
require("./outils/smoothScroll");
require("./components/carousels"); // Custom script
require("./components/accordion"); // Custom script
require("./components/testimonials-carousel"); // Custom script (slick carousel)
require("./outils/ajaxLogin"); // ajaxLogin
require("./components/forms"); // forms
require("./components/countdown"); // countdown
require("./components/scrollAnimate");

require("./outils/autosize"); // autosize textareas
require("./cart/cart"); // autosize textareas
