$('.testimonials:not(.testimonials--alt) .testimonials-carousel').slick({
	centerMode: true,
	centerPadding: '200px',
	slidesToShow: 1,
	responsive: [{
		breakpoint: 992,
		settings: {
			centerPadding: '0px',
		}
	}]
});
$('.testimonials--alt .testimonials-carousel').slick({
	// centerMode: true,
	// centerPadding: '200px',
	slidesToShow: 1,
	responsive: [{
		breakpoint: 992,
		settings: {
			centerPadding: '0px',
		}
	}]
});
