// Custom accordion homepage
$(function() {
    $('#accordion-choice').on('show.bs.collapse', function (e) {
        // ajoute la class "active" pour la version desktop
        $(e.target).parent(".card").addClass("active");
        $(e.target).parent(".card").siblings().removeClass("active");

        // Fait un scroll pour positionner l'élément à afficher
        $('html, body').animate({
          scrollTop: $('#accordion-choice').offset().top - 70
      }, 1500);
    })

    $('#accordion-choice').on('hide.bs.collapse', function (e) {
        // remove la class active (utilisé pour la version desktop)
        $(e.target).parent(".card").removeClass("active");
    })
});
// Custom accordion prix et réservation
$(function() {
    $('#accordion-bodyparts').on('show.bs.collapse', function (e) {
        // ajoute la class "active" pour la version desktop
        $(e.target).parent(".card").addClass("active");
        $(e.target).parent(".card").siblings().removeClass("active");

        // Fait un scroll pour positionner l'élément à afficher
        if(window.innerWidth < 768) {
            setTimeout(function() {
                $('html, body').animate({
                  scrollTop: $(e.target).parent(".card").offset().top - 70
                }, 1500);
    
            }, 300);
        }
    });

    $('#accordion-bodyparts').on('hide.bs.collapse', function (e) {
        // remove la class active (utilisé pour la version desktop)
        $(e.target).parent(".card").removeClass("active");
    })
})
