(function () {

    const countdownElement = document.getElementById( 'countdown' );
    const bannerSalesSection = document.querySelector( '.banner-sales-section' );
    const mainHeader = document.querySelector( '.main-header' );

    if ( bannerSalesSection ) {
        if ( mainHeader ) { mainHeader.classList.add( 'has-banner-sales' ) };
    }
    if(!countdownElement ) return;

    if ( mainHeader ) { mainHeader.classList.add( 'has-countdown' ) };

    const cdDaysElment = countdownElement.querySelector( '#days' ),
        cdHoursElment = countdownElement.querySelector( '#hours' ),
        cdMinutesElment = countdownElement.querySelector( '#minutes' ),
        cdSecondsElment = countdownElement.querySelector( '#seconds' );

    let isCounting = true;


    const countDownAt = countdownElement.dataset.countdownAt;

    function format( type = 'day', distance ) {
        const second = 1000,
          minute = second * 60,
          hour = minute * 60,
          day = hour * 24;

        let result = Math.floor( distance / ( day ) );

        if( type == 'hour' ) {
            result = Math.floor( ( distance % ( day ) ) / ( hour ) );
        }
        if( type == 'minute' ) {
            result = Math.floor( ( distance % ( hour )) / ( minute ) );
        }
        if( type == 'second' ) {
            result = Math.floor( ( distance % ( minute ) ) / second );
        }
        
        if( result < 0 ) {
            result = 0;
        }
        if( result < 10 ) {
            result = '0' + result;
        }
        return result; 
    }

    const countDown = new Date( countDownAt ).getTime(),
        x = setInterval(function() {    
  
          const now = new Date().getTime(),
                distance = countDown - now;
            if ( distance <= 0 ) {
                isCounting = false;
            }
            cdDaysElment.innerText = format( 'day', distance  ),
            cdHoursElment.innerText = format( 'hour', distance  ),
            cdMinutesElment.innerText = format( 'minute', distance  ),
            cdSecondsElment.innerText = format( 'second', distance  );
            
            if ( !isCounting ) {
                clearInterval( x );
                bannerSalesSection.remove();
            }
          //seconds
        }, 0);

    }());
