$(function () {
	$("[data-toggle='toggle-menu']").on("click", (e) => {
		const element = e.currentTarget.getAttribute("data-target");
		document.querySelector(`${element}`).classList.toggle("show")
	})
})


/**
 * Mediaquery validator
 * @param {*} element element 'window' à valider
 * @returns bool
 */
export function mq(element) {
	return (element.matches) ? true : false;
}

/**
 *
 * @param {*} element Objet - element 'window' à valider
 * @param {*} size Integer - taille min ou max
 * @param {*} minMaxMedia String - min-width, min-height, max-width, max-height
 * @returns bool
 */
export function responsive(element, size, minMaxMedia) {
	let x = element.matchMedia(`(${minMaxMedia} : ${size}px)`);

	mq(x);

	try {
		// Chrome & Firefox
		x.addEventListener('change', mq);
	} catch (e1) {
		try {
			// Safari
			x.addListener(mq);
		} catch (e2) {
			console.error(e2);
		}
	}

	return mq(x);
}


export const mql = window.matchMedia("(orientation: portrait)");


// SEPARATEUR DE MILLIERS
/**
 *
 * @param number - Number or String
 * @param separator - String - ',' by default
 */
export function formatNumber(number, separator = ',') {
	number += '';
	const x = number.split('.');
	let x1 = x[0];
	const x2 = x.length > 1 ? '.' + x[1] : '';
	const rgx = /(\d+)(\d{3})/;
	while (rgx.test(x1)) {
		x1 = x1.replace(rgx, '$1' + separator + '$2');
	}
	return x1 + x2;
}




// Change devise
/**
 * @param price String - Number price
 * @param symbol String devise example (chf, eur)
 * @param rate String or Number
 * @param separator String example: ',', '.' , etc
 */
export function changeDevise(price, symbol, rate, separator) {
	const newPrice = (symbol === 'eur' && price !== '') ? parseFloat(price) * parseFloat(rate) : price;
	return !isNaN(newPrice) ? formatNumber(newPrice, separator) : price;
}



/**
 *
 * @param {*} element string - id | class | tag
 * @param {*} referenceElement string - id | class | tag
 * @param {*} stickyClass string - css class to add to the element
 */
function sticky(element, referenceElement, stickyClass) {
	if ($(element).length > 0 && $(referenceElement).length > 0) {
		let sticky = false;
		let top = $(window).scrollTop();

		if ($(referenceElement).offset().top < top) {
			$(element).addClass(stickyClass);
			sticky = true;
		} else {
			$(element).removeClass(stickyClass);
		}
	}
}

// Init sticky
sticky(".main-header", "main", 'sticky'); // Header sticky en desktop
sticky(".main-cta", "main", 'sticky'); // Bouton réservation sticky en version mobile

$(window).on('scroll', function () {
	// btn contact desktop
	sticky(".main-header", "main", 'sticky'); // Header sticky en desktop
	sticky(".main-cta", "main", 'sticky'); // Bouton réservation sticky en version mobile
});


function showAndHide(parentElement, element) {
	if($(element).hasClass('show')) {
		$(element).removeClass('show');
		$(parentElement).removeClass('opened');

	}else {
		$(element).addClass('show');
		$(parentElement).addClass('opened');
	}
}

$('table.combined-offer thead').on('click', function (e) {
	showAndHide(this, $(this).parent('table').find('tbody'));
});



function createIframe( element, url ) {
	if( !url || !element ) return;

	const template = `<iframe src="${url}" sandbox="allow-same-origin allow-scripts allow-popups allow-forms"></iframe>`;
	element.innerHTML = template;
}

document.addEventListener( 'DOMContentLoaded', function(){
	$('.is-iframe-container').each(function () {
		const iframeUrl = $(this).text().trim();
		createIframe( this, iframeUrl );
	});
})
